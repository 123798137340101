import React from 'react'
import { graphql } from 'gatsby'

import { HomePageTemplate } from '../templates/home-page';
import TemplateWrapper from '../layouts';

export default class IndexPage extends React.Component {
  render() {
    const frontmatter = this.props.data.markdownRemark.frontmatter;

    return (
      <TemplateWrapper>
        <HomePageTemplate
          meta={frontmatter.meta}
          cookies={frontmatter.cookies}
          solution={frontmatter.solution}
          intro={frontmatter.intro}
          premiere={frontmatter.premiere}
          creative={frontmatter.creative}
          footer={frontmatter.footer}
        />
      </TemplateWrapper>
    )
  }
}

export const productPageQuery = graphql`
  query IndexPage {
    markdownRemark(frontmatter:{language: {eq: "en"}}) {
      frontmatter {
        solution
        footer
        meta {
          title
          description
        }
        cookies {
          gaTag
          message
          dismiss
          deny
          link
          url
        }
        intro {
          logo
          description
        }
        premiere {
          title
          description
          cta {
            text
            url
          }
        }
        creative {
          title
          description
          cta {
            text
            url
          }
        }
      }
    }
  }
`
